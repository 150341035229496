<template>
  <header class="w-full h-50vh overflow-hidden">
    <swiper
      :slides-per-view="1"
      :space-between="0"
      :loop="false"
      :effect="'fade'"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
      }"
      style="h-full"
    >
      <template v-for="(slide, index) in sliders" :key="slide.slider_id">
        <swiper-slide class="h-screen" v-if="index % 2 == 0 && sliders[index + 1]">
          <div class="h-full flex">
            <div class="item flex-1">
              <img
                :src="URL + sliders[index]?.image"
                alt=""
                class="object-cover w-full h-full"
              />
              <div
                class="absolute inset-0 flex items-center justify-center w-full header-overlay h-65vh"
              ></div>
            </div>
            <div class="item flex-1">
              <img
                :src="URL + sliders[index + 1]?.image"
                alt=""
                class="object-cover w-full h-full"
              />
              <div
                class="absolute inset-0 flex items-center justify-center w-full header-overlay h-65vh"
              ></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-screen" v-else-if="index % 2 == 0 && !sliders[index + 1]">
          <img
            :src="URL + sliders[index]?.image"
            alt=""
            class="object-cover w-full h-full"
          />
          <div
            class="absolute inset-0 flex items-center justify-center w-full header-overlay h-65vh"
          >
            <div class="font-medium leading-relaxed text-center text-white">
              <h1 class="w-full mb-5 text-5xl font-bold uppercase">
                {{ lang == 'ar' ? sliders[index].title_ar : sliders[index].title_en }}
                -
                {{ index }}
              </h1>
              <p class="text-lg">
                {{
                  lang == 'ar'
                    ? sliders[index].description_ar
                    : sliders[index].description_en
                }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </header>
</template>
<script>
import SwiperCore, { EffectFade, Autoplay, Pagination } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

// install Swiper modules
SwiperCore.use([EffectFade, Autoplay, Pagination])

export default {
  name: 'Header',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
    }
  },

  computed: {
    sliders() {
      console.log('the sliders', this.$store.getters.slider || [])
      return this.$store.getters.slider || []
    },
    lang() {
      return this.$root.$i18n.locale
    },
  },
}
</script>
<style lang="scss">
.header-overlay {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}
</style>
