<template>
  <div class="bg-light" :class="this.$i18n.locale == 'ar' ? 'rtl-style' : 'ltr-style'">
    <Nav />

    <router-view></router-view>

    <Footer />
  </div>
</template>
<script>
import Nav from './components/layouts/NavBar'
import Footer from './components/layouts/Footer'
import AOS from 'aos'

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
  },
  async created() {
    // ..

    try {
      await this.$store.dispatch('getSlider')
      await this.$store.dispatch('getBrands')
      await this.$store.dispatch('getNews')
      await this.$store.dispatch('getAddress')
      await this.$store.dispatch('getEmails')
      await this.$store.dispatch('getPhones')
      await this.$store.dispatch('getProducts')
      await this.$store.dispatch('getItem_product')
      await this.$store.dispatch('getImporter')
      await this.$store.dispatch('getItem_importer')
      await this.$store.dispatch('getWarehouses')
      await this.$store.dispatch('getGallery')
      document.getElementById('loading-bg').style.opacity = '0'
      setTimeout(() => {
        document.getElementById('app').style.opacity = '1'
      })
      document.getElementById('loading-bg').remove()

      AOS.init()
    } catch (error) {
      console.log(error)
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss"></style>
