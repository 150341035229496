{
  "lang": "ar",
  "nav": {
    "About": "حول الشركة",
    "contact": "تواصل معنا",
    "Products": "منتجاتنا",
    "News": "لوحة الإعلانات",
    "Warehouses": "مستودعاتنا ",
    "Gallery": "معرض الصور ",
    "Home": "الرئيسية"
  },
  "Home": {
    "Header": {
      "h1": "من خيرات الطبيعة نقدم الأفضل ",
      "button": "متابعة القراءة"
    },
    "landing": {
      "title1": "شركة نوارات",
      "description1": "   منذ اكثر من عشرون عاما ونحن نمارس نشاطنا حول تصنيع واستيراد المواد الغذائيه",
      "description2": " كانت بداية الشركه فى عام 2001 بقرار من امين اللجنة الشعبيه لشعبية طرابلس آن ذاك رقم 278 بتاريخ 19/07/2001 لاستيراد المواد الغذائيه وبما فى ذلك تملك العقارات والمنقولات اللازمة لنشاطها وكذلك العلامات والاسماء التجاريه وممارسة اعمال التوكيلات للشركات الوطنية والاجنبية   "
    },
    "latest-news": "اخر الأخبار",
    "contact": "تواصل معنا",
    "about": "حول نوارات ليبيا",
    "Read-more": "متابعة القراءة"
  },
  "About": {
    "header": {
      "h1": "حول الشركة",
      "name": "نوارات ليبيا"
    },
    "firstSection": {
      "p": "منذ اكثر من عشرين عاما ونحن نمارس نشاطنا فى تصنيع واستيراد المواد الغذائيه خاصة الالبان ومنتجاتها لخبرة ادارة الشركة فى هذا المجال لما يزيد عن اربعين عاما  وقد باشرت الشركة  البدء فى استيراد مواد اخرى كالطماطم والتن والحليب المكثف والتوسع قدر الامكان حسب حاجة السوق فى استيراد كل المتطلبات من المواد الغذائية الاخرى كالزيت والسكر والشاى والارز والمكرونه وغيرها"
    },
    "ourVision": {
      "h1": "رؤيتنا",
      "p": "تمتد شركتنا منذ عام 2001 لتضم أكثر من فرع وهُى نوارات ليبيا المساهمه ، وشركة نوارات ليبيا للمثلجات وتصل حتى شركتان أُخرتان مساعدتان ومقرهما الأردن والإمارات. "
    },
    "aboutDetails": {
      "ourVision": {
        "h1": "رؤيتنا",
        "p": "استيراد المواد ذات الجودة العاليه ومراعاة كافة القواعد والمواصفات الليبيه والتى تعد من ارقى المواصفات العالميه للمواد الغذائيه بعيدا عن المصادر الغير متخصصه والتى غالبا ماتنتج موادا رخيصه ولكن مواصفاتها متدنيه كما تهدف الشركة الى تنويع المواد المستوردة والتركيز على المواد الاساسيه التى تهم المواطن ولتكوين سلة استيراد من مواد مختلفه ومتميزه فى السوق"
      },
      "ourGoals": {
        "h1": "أهدافنا",
        "p": "النهوض باقتصاد بلادنا باستيراد اجود الانواع وافضلها والمساهمة قدر امكاننا فى تغيير ثقافة الاستهلاك الى الافضل من حيث الكم والكيف بما يعود على الصحة العامه بافضل النتائج دون الانجرار وراء سلع ومواد قد تنعكس على احداث وانتشار امراض قد تسبب الضرر البالغ للمجتمع"
      },
      "AlNawaratTeam": {
        "h1": "فريق النوّارات",
        "p":  "فريق النوارت هو سلاحنا الى تحقيق كل مانصبو اليه من خلال اعداد ذات كفاءة ومقدرة عاليه سواء فى اختيار المواد المستوردة او تسويقها بخبرات على مدى عشرات السنوات فى هذا المجال ومن خلال سياسة فعاله فى اعداد الدراسات اللازمه للاستيراد والتخزين والتسويق"  },
      "Ourservices": {
        "h1": "خدماتنا",
        "p" :"نحاول قدر الامكان توفير اجود المواد والوصول الى اغلب مناطق بلادننا سواء بتواجدنا المياشر اوعبر قنوات تسويق ثابته وريبه والمحافظة على السلع فى ضروف تخزين جيدة وتوفير كافة الخدمات التى تحول دون فساد السلع المخزنه او تعرضها للتلف والفساد خاصة السلع التى تحتاج الى طاقات تبريد ودرجات حراره منخفضه او مجمدة "
       }
    }
  },
  "News": {
    "header": {
      "h1": "لوحة الإعلانات"
    },
    "All": "الكل",
    "News": "الأخبار",
    "Advertising": "الاعلانات "
  },
  "Warehouses": {
    "h1": " مخازن  الشركة ",
    "description": " لدينا مخازن عملاقة ",
    "text_haeader": " بيانات المخزن ",
    "type": "النوع",
    "status": "الحالة",
    "name": "الإسم",
    "warehouse_number": "مخزن رقم",
    "warehouse_address": "مكان المخزن",
    "location_on_map": "الموقع على الخريطة",
    "not_cold_warehouse": "مخزن غير مُبرّد",
    "cold_warehouse": "مخزن مُبرّد"
  },
  "Gallery": {
    "h1": "  معرض الصور  ",
    "description": "   معرض الصور "
  },

  "products": {
    "h1": "المنتجات",
    "description": "يمكنك أن تجد في هذه الصفحة نموذج اتصال إذا كنت تريد الاتصال بنا بخصوص أسئلتك أو طلباتك حول منتجاتنا.",
    "quality": "جودتنا",
    "experience": "خبرتنا",
    "quality_details": "نحن متحمسون للمعرفة والتحسين المستمر لتحقيق المنتج المثالي والتجربة الفريدة في كل عميل.",
    "experience_details": "نحن متحمسون للمعرفة والتحسين المستمر لتحقيق المنتج المثالي والتجربة الفريدة في كل عميل ",
    "seeAllProducts": "عرض جميع المنتجات",
    "search": "بحث"
  },
  "footer": {
    "contact": "تواصل معنا",
    "place": "اين نحن:",
    "address": "العنوان:",
    "copyright": "جميع الحقوق محفوظة لشركة نورات ليبيا "
  },
  "subscribe": {
    "title": "الاشتراك في النشرة الإخبارية",
    "subTitle": "أدخل بريدك الإلكتروني لتلقي آخر أخبارنا"
  },
  "Brands": {
    "OurBrands": "علاماتنا التجارية"
  },
  "Contact": {
    "ContactUS": "تواصل معنا",
    "ContactP": "يمكنك أن تجد في هذه الصفحة نموذج اتصال إذا كنت تريد الاتصال بنا بخصوص أسئلتك أو طلباتك حول منتجاتنا",
    "follow": "تابعنا",
    "form": {
      "name": "الاسم كامل",
      "company_name": "اسم الشركة",
      "email": "البريد الإلكتروني",
      "message": "الرسالة",
      "Submit": "تأكيد",
      "required_field": "يجب كتابة هذا الحقل"
    },
    "Success": "تم الارسال بنجاح، شكرا لتواصلك معنا",
    "error": "حدث خطأ ما الرجاء إعادة المحاولة لاحقا"
  },
  "footerHeroText": "نور التجارة العربية",
  "login": "تسجيل الدخول"
}
