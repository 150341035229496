<template>
  <div class="bg-dark py-4 px-16 text-white">
    <footer class="flex justify-between items-center">
      <div class="section width-fit-content text-center">
        <div class="img-div">
          <img
            class="object-cover mx-auto"
            loading="lazy"
            src="../../assets/logo.png"
            alt=""
            @click="$router.push('/')"
          />
        </div>
        <div>
          <h6 class="font-medium">Nowarat</h6>
          <p class="font-light">{{ $t('footerHeroText') }}</p>
        </div>
      </div>
      <div class="section">
        <ul class="flex items-center justify-around gap-8">
          <li v-for="(link, index) in socialLinks" :key="index">
            <a :href="link.link" target="_blank"><span v-html="link.icon"></span></a>
          </li>
        </ul>
        <div class="text-center mt-4">
          <a href="https://ls34.server.ly:2096/webmaillogout.cgi">{{ $t('login') }}</a>
        </div>
      </div>
      <div class="section text-center">
        <h3 class="mb-2 font-medium">
          {{ $t('footer.place') }}
        </h3>
        <p
          class="flex items-center my-2"
          v-for="address in addresses"
          :key="address.addresses"
        >
          <span class="font-medium">{{ $t('footer.address') + ' ' }}</span>
          <span class="font-light">{{
            locale == 'ar' ? address.name_ar : address.name_en
          }}</span>
        </p>
      </div>
      <div class="section">
        <div>
          <!-- <h3 class="mb-2 text-lg font-medium md:text-2xl">
          {{ $t('footer.contact') }}
        </h3> -->
          <a
            class="flex items-center justify-center my-2"
            v-for="phone in phones"
            :key="phone.phone_id"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.6668 28.2001V33.2001C36.6686 33.6642 36.5736 34.1237 36.3876 34.549C36.2017 34.9743 35.9289 35.3561 35.5869 35.6699C35.2449 35.9836 34.841 36.2226 34.4013 36.3713C33.9616 36.52 33.4957 36.5752 33.0334 36.5334C27.9048 35.9761 22.9784 34.2237 18.6501 31.4167C14.6231 28.8579 11.209 25.4437 8.65009 21.4167C5.83339 17.0688 4.0805 12.1184 3.53343 6.96675C3.49178 6.50586 3.54655 6.04135 3.69426 5.60279C3.84197 5.16423 4.07938 4.76123 4.39137 4.41945C4.70336 4.07767 5.0831 3.8046 5.50641 3.61762C5.92972 3.43064 6.38733 3.33385 6.85009 3.33341H11.8501C12.6589 3.32545 13.4431 3.61188 14.0564 4.1393C14.6696 4.66672 15.0702 5.39916 15.1834 6.20008C15.3945 7.80019 15.7858 9.37129 16.3501 10.8834C16.5743 11.48 16.6229 12.1283 16.4899 12.7515C16.357 13.3748 16.0482 13.9469 15.6001 14.4001L13.4834 16.5167C15.856 20.6893 19.3108 24.1442 23.4834 26.5167L25.6001 24.4001C26.0532 23.952 26.6253 23.6432 27.2486 23.5102C27.8719 23.3773 28.5202 23.4258 29.1168 23.6501C30.6289 24.2143 32.2 24.6057 33.8001 24.8167C34.6097 24.931 35.3491 25.3388 35.8776 25.9626C36.4062 26.5864 36.687 27.3827 36.6668 28.2001Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mx-2 text-xs">{{ phone.phone }}</span>
          </a>
          <a
            class="flex items-center my-2 text-xs"
            v-for="email in emails"
            :key="email.email_is"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66671 6.66675H33.3334C35.1667 6.66675 36.6667 8.16675 36.6667 10.0001V30.0001C36.6667 31.8334 35.1667 33.3334 33.3334 33.3334H6.66671C4.83337 33.3334 3.33337 31.8334 3.33337 30.0001V10.0001C3.33337 8.16675 4.83337 6.66675 6.66671 6.66675Z"
                stroke="#FFFDFD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M36.6667 10L20 21.6667L3.33337 10"
                stroke="#FFFDFD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mx-2 text-xs md:text-lg">{{ email.link }}</span></a
          >
        </div>
      </div>
    </footer>
    <p class="py-5 text-center text-gray-500">
      {{ $t('footer.copyright') + ' &copy; ' + new Date().getFullYear() }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      socialLinks: [
        {
          link: '',
          icon: '<svg width="22" height="35" viewBox="0 0 22 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5583 19.6875L21.6991 13.3533H14.5661V9.24287C14.5661 7.50996 15.5625 5.8208 18.7572 5.8208H22V0.42793C22 0.42793 19.0573 0 16.2437 0C10.3694 0 6.52972 3.03379 6.52972 8.52578V13.3533H0V19.6875H6.52972V35H14.5661V19.6875H20.5583Z" fill="white"/></svg>',
        },
        {
          link: '',
          icon: '<svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4024 7.47654C31.4246 7.80464 31.4246 8.13283 31.4246 8.46093C31.4246 18.4687 24.207 30 11.0153 30C6.95115 30 3.17578 28.7578 0 26.6016C0.577434 26.6719 1.13258 26.6953 1.73223 26.6953C5.08562 26.6953 8.1726 25.5 10.6377 23.461C7.48415 23.3906 4.84137 21.211 3.93082 18.2109C4.37502 18.2812 4.81915 18.3281 5.28557 18.3281C5.92958 18.3281 6.57367 18.2343 7.17325 18.0703C3.88645 17.3671 1.42127 14.3203 1.42127 10.6406V10.5469C2.37618 11.1094 3.48668 11.461 4.66363 11.5078C2.73151 10.1484 1.4657 7.82809 1.4657 5.20307C1.4657 3.79685 1.82096 2.50779 2.44283 1.38278C5.97395 5.97652 11.2817 8.97647 17.2335 9.30466C17.1224 8.74215 17.0558 8.15627 17.0558 7.57032C17.0558 3.3984 20.2538 0 24.229 0C26.2944 0 28.1599 0.91406 29.4702 2.39062C31.0913 2.06252 32.6459 1.42967 34.0229 0.562504C33.4898 2.32036 32.3572 3.79692 30.8693 4.73435C32.3129 4.57037 33.712 4.1484 35 3.56253C34.023 5.06246 32.8015 6.39835 31.4024 7.47654Z" fill="white"/></svg>',
        },
        {
          link: '',
          icon: '<svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.61054 33H0.561607V10.9676H7.61054V33ZM4.08228 7.96216C1.82826 7.96216 0 6.15007 0 3.9623C1.61333e-08 2.91143 0.430096 1.90361 1.19567 1.16053C1.96125 0.417455 2.99959 0 4.08228 0C5.16496 0 6.20331 0.417455 6.96888 1.16053C7.73446 1.90361 8.16455 2.91143 8.16455 3.9623C8.16455 6.15007 6.33554 7.96216 4.08228 7.96216ZM33.9924 33H26.9587V22.2748C26.9587 19.7187 26.9055 16.4407 23.2938 16.4407C19.6289 16.4407 19.0673 19.2178 19.0673 22.0906V33H12.026V10.9676H18.7865V13.973H18.8852C19.8263 12.2419 22.125 10.4151 25.5546 10.4151C32.6886 10.4151 34 14.9748 34 20.8973V33H33.9924Z" fill="white"/></svg>',
        },
      ],
    }
  },
  computed: {
    emails() {
      return this.$store.getters.email
    },
    addresses() {
      return this.$store.getters.address
    },
    phones() {
      return this.$store.getters.phone
    },
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>

<style scoped>
@media (max-width: 991px) {
  footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
img {
  width: 75px;
}
.width-fit-content {
  width: fit-content;
}
</style>
