import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('../views/NewsDetails.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/AllProducts',
    name: 'AllProducts',
    component: () => import('../views/AllProducts.vue'),
  },
  {
    path: '/product/:product_id',
    name: 'product',
    component: () => import('../views/product.vue'),
  },
  {
    path: '/Warehouses',
    name: 'Warehouses',
    component: () => import('../views/Warehouses.vue'),
    meta: {
      showProgressBar: true,
    },
  },
  {
    path: '/Gallery',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue'),
    meta: {
      showProgressBar: true,
    },
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),

  routes,
})

export default router
