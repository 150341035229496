import { createStore } from 'vuex'
import axios from '../axios'

export default createStore({
  state: {
    sliderDate: null,
    brands: null,
    news: null,
    Advertising: null,
    address: null,
    email: null,
    phone: null,
    products: null,
    item_product: null,
    importer: null,
    item_importer: null,
    specificPost: null,
    warehouses: null,
    gallery: null,
  },
  mutations: {
    setWarehouses(state, payload) {
      state.warehouses = payload
    },
    setGallery(state, payload) {
      state.gallery = payload
    },
    setSlider(state, payload) {
      state.sliderDate = payload
    },
    setBrands(state, payload) {
      state.brands = payload
    },
    setPosts(state, payload) {
      state.news = payload.filter((item: any) => item.type == 'News')
      state.Advertising = payload.filter((item: any) => item.type == 'Advertising')
    },
    setSpecificPost(state, payload) {
      state.specificPost = payload
    },
    setAdress(state, payload) {
      state.address = payload
    },
    setEmails(state, payload) {
      state.email = payload
    },
    setPhones(state, payload) {
      state.phone = payload
    },
    setPoducts(state, payload) {
      state.products = payload
    },
    setItemPoducts(state, payload) {
      state.item_product = payload
    },
    setImporter(state, payload) {
      state.importer = payload
    },
    setItem_importer(state, payload) {
      state.item_importer = payload
    },
  },
  actions: {
    async getWarehouses({ commit }) {
      try {
        const { data } = await axios.get('/store')
        commit('setWarehouses', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getGallery({ commit }) {
      try {
        const { data } = await axios.get('/gallery')
        commit('setGallery', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getSlider({ commit }) {
      try {
        const { data } = await axios.get('/slider')
        commit('setSlider', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getBrands({ commit }) {
      try {
        const { data } = await axios.get('/our_partner')
        commit('setBrands', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getNews({ commit }) {
      try {
        const { data } = await axios.get('/post')
        commit('setPosts', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getAddress({ commit }) {
      try {
        const { data } = await axios.get('/our_address')
        commit('setAdress', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getEmails({ commit }) {
      try {
        const { data } = await axios.get('/email')
        commit('setEmails', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getPhones({ commit }) {
      try {
        const { data } = await axios.get('/phone')
        commit('setPhones', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getProducts({ commit }) {
      try {
        const { data } = await axios.get('/product')
        commit('setPoducts', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getItem_product({ commit }) {
      try {
        const { data } = await axios.get('/item_product')
        commit('setItemPoducts', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getImporter({ commit }) {
      try {
        const { data } = await axios.get('/importer')
        commit('setImporter', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getItem_importer({ commit }) {
      try {
        const { data } = await axios.get('/item_importer')
        commit('setItem_importer', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    subscribe(_, email) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/subscribe', { email })
          .then((res: any) => {
            resolve(res)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    },
    contact(_, payload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/contact_us', payload)
          .then((res: any) => {
            resolve(res)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    },
  },
  getters: {
    slider(state) {
      return state.sliderDate
    },
    brands(state) {
      return state.brands
    },
    news(state) {
      return state.news
    },
    Advertising(state) {
      return state.Advertising
    },
    address(state) {
      return state.address
    },
    email(state) {
      return state.email
    },
    phone(state) {
      return state.phone
    },
    products(state) {
      return state.products
    },
    item_product(state) {
      return state.item_product
    },
    importer(state) {
      return state.importer
    },
    item_importer(state) {
      return state.item_importer
    },
    warehouses(state) {
      return state.warehouses
    },
    gallery(state) {
      return state.gallery
    },
  },
})
