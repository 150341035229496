{
  "lang": "en",
  "nav": {
    "About": "About us",
    "contact": "Contact us",
    "Products": "products",
    "News": "News",
    "Home": "Home",
    "Warehouses": "Warehouses",
    "Gallery": "Gallery"
  },
  "Home": {
    "Header": {
      "h1": "From the bounties of nature we offer the best",
      "button": "Read more "
    },
    "landing": {
      "title1": " Nowarat Company",
      "description1": "For twenty years now, we continue to embrace and practice our commercial activities, which revolve around the manufacture, import and export of canned food dairy products and ice cream.",
      "description2": "Our company has extended since 2001 to include more than one branch. They are Nowarat Libya, Nowarat Libya Ice Cream Company, and up to two other auxiliary companies based in Jordan and the UAE."
    },
    "latest-news": "LAST NEWS",
    "contact": "contact us",
    "about": "Read our story",
    "Read-more": "Read more"
  },
  "About": {
    "header": {
      "h1": "About us",
      "name": "Nowarat Libya"
    },
    "firstSection": {
      "p": "Due to the company's management expertise in this sector of more than forty years, we have been exercising our business in manufacturing and importing foodstuffs, particularly dairy products, for more than twenty years. The firm has begun to import additional materials such as tomatoes, tuna, and condensed milk, and has expanded as far as possible in response to market demand to import all requirements from additional goods such as oil, sugar, tea, rice, pasta, and so on. "
    },
    "ourVision": {
      "h1": "Company history",
      "p": " Since 2001, our firm has grown to incorporate many branches, including Nowarat Libya Shareholding firm, Nowarat Libya Ice Cream Company, and up to two more auxiliary companies situated in Jordan and the UAE. "   },
    "aboutDetails": {
      "ourVision": {
        "h1": "Our vision",
        "p": "Importing high-quality supplies while adhering to all Libyan norms and requirements, which are among the highest worldwide standards for foodstuffs, and staying away from non-specialized suppliers that frequently manufacture cheap products with poor specifications. The firm also intends to diversify imported commodities and focus on fundamental materials of interest to citizens, as well as to develop an import basket of materials. Unique and different in the market "      },
      "ourGoals": {
        "h1": "our goals",
        "p": "Promoting our country's economy by importing the finest and best types and doing everything we can to change the culture of consumption for the better in terms of quantity and quality, in a way that brings the best results to public health without being drawn into goods and materials that may result in the creation and spread of diseases that may cause severe harm to society."
      },
      "AlNawaratTeam": {
        "h1": "Nowarat Team",
        "p": "The Al-Nowarat team is our weapon for achieving everything we strive for, whether it is through highly efficient and qualified personnel, whether in selecting imported materials or marketing them, with decades of experience in this field and an effective policy in preparing the necessary studies for import, storage, and marketing."     },
      "Ourservices": {
        "h1": " Our services",
        "p": "We make every effort to provide the finest materials and reach the majority of our country's regions, whether through our direct presence or through established and reliable marketing channels, to preserve the goods in good storage conditions, and to provide all services that prevent the stored goods from spoiling or being exposed to damage and spoilage, particularly the goods that require cooling energy and low temperatures or 'frozen'"
      }
    }
  },
  "Warehouses": {
    "h1": "our Warehouses",
    "description": " We have great Warehouses  ",
    "text_haeader": " Detail of the Warehouses  ",
    "type": "type",
    "status": "status",
    "name": "name",
    "warehouse_number": "warehouse NO.",
    "warehouse_address": "warehouse location",
    "location_on_map": "location on map",
    "not_cold_warehouse": "not cold warehouse",
    "cold_warehouse": "cold warehouse"
  },
  "Gallery": {
    "h1": " Gallery ",
    "description": "  Gallery"
  },
  "News": {
    "header": {
      "h1": "Advertisements Board"
    },
    "All": "All",
    "News": "News",
    "Advertising": "Advertising"
  },
  "products": {
    "h1": "products",
    "description": "You can find on this page a contact form if you want to contact us regarding your questions or requests about our products.",
    "quality": "quality",
    "experience": "Our experience",
    "quality_details": "We are passionate about knowledge and continuous improvement to achieve the perfect product and unique experience in each customer.",
    "experience_details": "We are passionate about knowledge and continuous improvement to achieve the perfect product and unique experience in each customer.",
    "seeAllProducts": "See all products",
    "search": "Search"
  },
  "footer": {
    "contact": "Contact with us",
    "place": "Where are we:",
    "address": "Address:",
    "copyright": "Copyright Nowarat Co "
  },
  "subscribe": {
    "title": "NEWSLETTER SUBSCRIPTION",
    "subTitle": "Enter your email to receive our latest news."
  },
  "Brands": {
    "OurBrands": "Our Brands"
  },
  "Contact": {
    "ContactUS": " CONTACT US",
    "ContactP": " You can find on this page a contact form if you want to contact us regarding your questions or requests about our products.",
    "follow": "Follow us",
    "form": {
      "name": "Full Name",
      "company_name": "Company Name",
      "email": "Email Address",
      "message": "Message",
      "Submit": "Submit",
      "required_field": "This field is required"
    },
    "Success": "message sent successfully, Thanks you for  contact with use",
    "error": "something went wrong, please try again later"
  },
  "footerHeroText": "The Light of Arab Trade.",
  "login": "Login"
}
