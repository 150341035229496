
import Header from '../components/Home/Header.vue'
import Subscribe from '../components/layouts/Subscribe.vue'
import Brands from '../components/layouts/Brands.vue'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

// // Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { computed } from 'vue'
import { useStore } from 'vuex'

// import { useI18n } from 'vue-i18n'

export default {
  name: 'Home',
  components: {
    Header,
    Subscribe,
    Brands,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore()
    const news = computed(() => store.getters.news)
    const windowWidth = computed(() => window.innerWidth)
    // const { t, locale } = useI18n({ useScope: 'global' })

    return {
      URL: 'https://api.nowarat.ly/',
      news,
      modules: [Navigation, Pagination, Scrollbar, A11y],
      windowWidth,
      // locale,
      // t,
    }
  },
}
