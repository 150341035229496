<template>
  <nav class="sticky top-0 z-50 w-screen bg-white">
    <div class="mx-auto px-8 shrink-padding">
      <div
        v-if="!toggle"
        class="md:hidden p-5 flex justify-between bg-white md:rounded-xl"
      >
        <img
          alt=""
          height="80"
          width="80"
          class="object-contain"
          src="../../assets/logo.svg"
          loading="lazy"
          @click="$router.push('/')"
        />
        <button @click="toggle = !toggle">
          <img src="../../assets/Group3.svg" alt="" loading="lazy" />
        </button>
      </div>
      <div
        v-if="toggle"
        class="relative mx-auto bg-white md:rounded-xl py-5 md:py-5 md:px-2 md:flex items-center justify-between"
      >
        <div class="flex md:hidden items-center justify-between w-full px-5">
          <div
            class="flex items-center justify-center text-dark text-lg"
            :class="local == 'ar' && 'flex-row-reverse'"
          >
            <span>AR</span>
            <button
              class="outline-none focus:outline-none w-12 h-5 rounded-lg bg-gray-300 mx-1 relative transition-all duration-500"
              @click="
                local == 'ar' ? (this.$i18n.locale = 'en') : (this.$i18n.locale = 'ar')
              "
            >
              <span
                class="absolute bg-primary rounded-full h-5 w-5 top-0"
                :class="local == 'ar' ? 'left-0' : 'right-0'"
              ></span>
            </button>
            <span>EN</span>
          </div>
          <button @click="toggle = !toggle" class="md:hidden">
            <img src="../../assets/Union.svg" alt="" loading="lazy" />
          </button>
        </div>
        <div
          class="md:flex items-center justify-around text-dark w-full md:w-1/2 font-medium tracking-wide leading-relaxed my-5 md:my-0 img-and-links-container"
        >
          <img
            alt=""
            height="80"
            width="80"
            class="hidden md:block object-contain mx-auto md:mx-0 cursor-pointer"
            src="../../assets/logo.svg"
            @click="
              $router.push('/')
              // mobileWidth ? (toggle = false) : null
            "
          />
          <router-link
            v-for="(link, index) in links"
            :key="index"
            class="md:text-center w-full block md:inline md:w-auto px-5 md:px-0 py-5 md:my-0"
            :class="
              link.link == $route.name
                ? 'bg-primary md:bg-transparent text-white md:text-primary font-bold'
                : null
            "
            :to="{ name: link.link }"
            @click="mobileWidth ? (toggle = false) : null"
            ><span> {{ link.name }}</span>
            <span
              v-if="link.link == $route.name"
              class="md:bg-primary md:h-1 rounded-md md:block md:w-16 mx-auto mt-1"
            ></span>
          </router-link>
          <router-link
            class="md:hidden w-full block px-5 md:px-0 py-5 md:my-0"
            :class="
              $route.name == 'Contact'
                ? 'bg-primary md:bg-transparent text-white md:text-primary font-bold'
                : null
            "
            :to="{ name: 'Contact' }"
            @click="mobileWidth ? (toggle = false) : null"
            ><span> {{ $t('nav.contact') }}</span>
          </router-link>
        </div>
        <div class="md:flex items-center">
          <router-link
            :to="{ name: 'Contact' }"
            class="hidden text-white mx-auto md:mx-4 md:inline my-8 md:my-0 justify-center transform hover:scale-105 transition-all duration-300"
            @click="mobileWidth ? (toggle = false) : null"
          >
            <span class="bg-primary px-4 py-2 hover:shadow-xl rounded-lg">{{
              $t('nav.contact')
            }}</span></router-link
          >
          <div
            class="hidden md:flex items-center justify-center text-dark text-lg"
            :class="local == 'ar' && 'flex-row-reverse'"
          >
            <span>AR</span>
            <button
              class="outline-none focus:outline-none w-12 h-5 rounded-lg bg-gray-300 mx-1 relative transition-all duration-500"
              @click="
                local == 'ar' ? (this.$i18n.locale = 'en') : (this.$i18n.locale = 'ar')
              "
            >
              <span
                class="absolute bg-primary rounded-full h-5 w-5 top-0"
                :class="local == 'ar' ? 'left-0' : 'right-0'"
              ></span>
            </button>
            <span>EN</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Nav',
  data() {
    return {
      toggle: true,
      mobileWidth: false,
    }
  },
  created() {
    if (window.innerWidth > 768) {
      this.toggle = true
    } else {
      this.toggle = false
      this.mobileWidth = true
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        if (!this.toggle) {
          this.toggle = true
        }
        this.mobileWidth = false
      } else {
        if (this.toggle) {
          this.toggle = false
        }
        this.mobileWidth = true
      }
    })
  },
  computed: {
    local() {
      return this.$i18n.locale
    },
    links() {
      return [
        { link: 'Home', name: this.$t('nav.Home') },
        { link: 'About', name: this.$t('nav.About') },
        { link: 'News', name: this.$t('nav.News') },
        { link: 'Gallery', name: this.$t('nav.Gallery') },
        { link: 'Warehouses', name: this.$t('nav.Warehouses') },
        { link: 'Products', name: this.$t('nav.Products') },
      ]
    },
  },
}
</script>

<style scoped>
.img-and-links-container a {
  font-weight: bold;
}
@media (max-width: 1300px) {
  .img-and-links-container {
    flex: 0.85;
    gap: 0.1rem;
  }
  .img-and-links-container a {
    font-size: 0.75rem;
  }
}
@media (max-width: 1100px) {
  .shrink-padding {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
</style>
