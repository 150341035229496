// axios
import axios from "axios";

const baseURL = "https://api.nowarat.ly/api/";

export default axios.create({
  baseURL,
  headers: { Accept: "application/json" },
  // You can add your headers here
});
