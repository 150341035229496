<template>
  <div data-aos="fade-up" class="w-full my-16">
    <div
      class="w-11/12 md:w-3/4 mx-auto bg-primary rounded-2xl p-8 md:p-12 grid grid-cols-3 gap-5 items-center text-white leading-relaxed tracking-wide"
    >
      <img class="md:row-span-2 mx-auto" src="../../assets/Vector.svg" alt="" />
      <div class="col-span-2 leading-relaxed">
        <h6 class="text-2xl font-bold">{{ $t('subscribe.title') }}</h6>
        <p class="my-3">{{ $t('subscribe.subTitle') }}</p>
      </div>
      <p class="p-3 my-5 text-green-600 rounded-md bg-green-50" v-if="success">
        تم الإشتراك في النشرة الإخبارية بنجاح
      </p>
      <form
        @submit.prevent="submit"
        class="col-span-3 md:col-span-2 md:col-start-2 flex items-center relative"
      >
        <input
          type="email"
          name="email"
          id="email"
          :placeholder="locale == 'ar' ? 'البريد الألكتروني' : 'email address'"
          v-model="email"
          @keyup.enter="submit"
          class="bg-white text-gray-500 font-medium focus:bg-gray-100 focus:outline-none px-5 py-3 rounded-xl w-full"
        />
        <img
          v-if="!loading && !success"
          @click="submit"
          class="absolute transform cursor-pointer"
          :class="$i18n.locale == 'ar' ? 'left-5 rotate-180' : ' right-5'"
          src="../../assets/Arrow1.svg"
          alt=""
        />
        <svg
          v-if="loading"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          class="absolute animate-spin"
          :class="$i18n.locale == 'ar' ? 'left-5' : ' right-5'"
          height="24"
          viewBox="0 0 24 24"
          style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
        >
          <circle cx="12" cy="20" r="2"></circle>
          <circle cx="12" cy="4" r="2"></circle>
          <circle cx="6.343" cy="17.657" r="2"></circle>
          <circle cx="17.657" cy="6.343" r="2"></circle>
          <circle cx="4" cy="12" r="2.001"></circle>
          <circle cx="20" cy="12" r="2"></circle>
          <circle cx="6.343" cy="6.344" r="2"></circle>
          <circle cx="17.657" cy="17.658" r="2"></circle>
        </svg>
        <svg
          v-if="success"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          class="absolute"
          :class="$i18n.locale == 'ar' ? 'left-5' : ' right-5'"
          height="24"
          viewBox="0 0 24 24"
          style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
        >
          <path
            d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"
          ></path>
        </svg>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'subscribe',
  data() {
    return {
      email: '',
      loading: false,
      success: '',
      error: '',
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {
    submit() {
      if (!this.email) return
      this.loading = true
      this.$store
        .dispatch('subscribe', this.email)
        .then(() => {
          this.success = true
          this.email = ''
          setTimeout(() => {
            this.success = false
          }, 8000)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
