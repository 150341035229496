<template>
  <div class="mx-auto flex justify-center my-12">
    <router-link
      class="bg-dark transform text-white inline-block transition-all hover:scale-110 duration-300 shadow-md hover:shadow-xl rounded-xl px-12 py-3 text-lg"
      :to="{ name: 'Products' }"
      >{{ $t('Brands.OurBrands') }}</router-link
    >
  </div>
  <section
    class="brands-container relative flex items-center justify-center gap-32 overflow-x-scroll hidden-scrollbar my-24"
  >
    <div v-for="{ image } in brands" :key="image">
      <img :src="URL + image" alt="" class="h-24 w-28" loading="lazy" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'brands',
  data() {
    return {
      URL: 'https://api.nowarat.ly/',
    }
  },
  computed: {
    brands() {
      return this.$store.getters.brands
    },
  },
}
</script>
<style>
.hidden-scrollbar::-webkit-scrollbar {
  width: 0px !important;
}
/* Track */
.hidden-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.hidden-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.ainmate {
  -webkit-animation: slide 90s linear infinite alternate;
  animation: slide 90s linear infinite alternate;
}

.ainmate:hover {
  animation-play-state: paused;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* new styles */
@media (max-width: 767px) {
  .brands-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 1fr);
    row-gap: 4rem;
  }
}
</style>
