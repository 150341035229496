import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
// import { VueRecaptchaPlugin } from 'vue-recaptcha'

// Import Swiper styles
import 'aos/dist/aos.css'
// import "swiper/swiper.scss";
import 'animate.css'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/modules/effect-fade/effect-fade.min.css'
import './index.css'

const Vue = createApp(App)

Vue.use(i18n)
Vue.use(store)
Vue.use(router)
// Vue.use(VueRecaptchaPlugin, {
//   //   v2SiteKey: 'YOUR_V2_SITEKEY_HERE',
//   v3SiteKey: '6LebO7woAAAAADtog4nmhCIsby7zdjK64oAdtBxC',
// })
Vue.mount('#app')
